// 发布内容
import request from '../request'

export default class Issue {
    /**
* 上传接口
* @param {*} params
* @returns
*/
    static upload(data) {
        return request.post('/api/common/upload', data)
    }
    /**
   * 获取发布提问里的区域
   * @param {*} params
   * @returns
   */
    static unitList() {
        return request.get('/api/v1/department/unitList')
    }
    /**
* 手机验证码登录
* @param {*} params
* @returns
*/
    static mobilelogin(data) {
        return request.post('/api/sms/send', data)
    }
    /**
* 发帖
* @param {*} params
* @returns
*/
    static addArticleCard(data) {
        return request.post('/api/v1/articlecurrency/addArticleCard', data)
    }
    /**
* 草稿箱详情
* @param {*} params 
* @returns 
*/
    static articleDraftinfo(params) {
        return request.get("/api/v1/articlecurrency/articleDraftinfo", {
            params
        })
    }
    /**
* 受理单位
* @param {*} params
* @returns
*/
    static unitList() {
        return request.get('/api/v1/department/unitList')
    }
    /**
* 编辑发帖
* @param {*} params
* @returns
*/
    static updateArticleCard(data) {
        return request.post('/api/v1/articlecurrency/updateArticleCard', data)
    }

    /**
* 敏感词查询
* @param {*} params 
* @returns 
*/
    static sensitive(data) {
        return request.post("/api/v1/articlecurrency/sensitive", data)
    }
    /**
*  全文搜索 —— 小程序
* @param {*} params 
* @returns 
*/
    static articleSearchWechat(params) {
        return request.get("/api/v1/articlesearch/search_title", {
            params
        })
    }
  
}
